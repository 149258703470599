import { zIndex } from "@constants/zIndex";

const handleSmartBanner = (visible: boolean) => window.eventBus.dispatch("smartBanner", { visible });
export default async function loadAppSmartBanner(appToken: string) {
  const AdjustSmartBanner = (await import("@adjustcom/smart-banner-sdk")).default;

  // Create a custom div for the banner
  const bannerDiv = document.createElement("div");
  bannerDiv.style.cssText = `
    position: fixed;
    z-index: ${zIndex.smartBanner};
    top: 0;
    left: 0;
    right: 0;
  `;
  document.body.appendChild(bannerDiv);

  AdjustSmartBanner.init({
    appToken,
    bannerParent: bannerDiv,
    onCreated: () => handleSmartBanner(true),
    onDismissed: () => handleSmartBanner(false),
  });
}
