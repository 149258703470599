import { styled } from "@mui/material";
import * as StyledTextFrame from "../TextFrame/TextFrame.styled";

export const Wrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
});

export const NoConsentWrapper = styled(StyledTextFrame.Wrapper)({
  alignItems: "center",
});
