import Tealium from "@4tn/webx-analytics";
import { CONTENT_LANGUAGE } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import getPageCategory, { getRouteSlug, isVideoPage } from "./getPageCategory";

export default function trackContentClick({
  position,
  url,
  label,
  category,
  createdAt,
  updatedAt,
  title,
  videoGuid,
  hasVideo,
  sponsor,
}: {
  position?: number;
  label: string;
} & PageLink) {
  const pageCategory = getPageCategory(url, hasVideo, !!sponsor);

  Tealium.link({
    event_value: position || null,
    event_category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
    event_name: TEALIUM_EVENT_NAME.CONTENT_LISTING_CLICK,
    event_label: label,
    content_category: category?.title || null,
    content_datecreated: createdAt || null,
    content_datepublished: createdAt || null,
    content_id: getRouteSlug(url),
    content_language: CONTENT_LANGUAGE,
    content_datemodified: updatedAt || null,
    content_name: title,
    content_type: pageCategory,
    ...(isVideoPage(url) && {
      event_name: TEALIUM_EVENT_NAME.CONTENT_LISTING_PLAY,
      media_name: title,
      media_id: videoGuid,
    }),
  });
}
