import { styled } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import DynamicContent from "@pageContent/DynamicContent";
import { InfiniteCollectionContainer } from "@pageContent/common/InfiniteCollection/InfiniteCollection";
import InfiniteCollectionSkeleton from "@pageContent/common/Skeletons/InfiniteCollectionSkeleton";
import { useHostConfig } from "@utils/common/getHostConfig";
import { useNoticeError } from "@utils/common/newRelic";
import { fetchByEndpoint } from "@utils/pageContent/fetchData";
import useFetchLimit from "@utils/pageContent/useFetchLimit";

const placeholderSearchModalCollectionQueryKey = "placeholder-search-modal-collection";

const SearchModalPlaceholder: React.FC = () => {
  const {
    searchModal,
    designTokens: { articleGrid },
  } = useHostConfig();
  const { placeholderCollectionUrl } = searchModal || {};

  const {
    data: placeholderCollection,
    isLoading,
    error,
  } = useQuery<CategoryBasedCollection>({
    queryKey: [placeholderSearchModalCollectionQueryKey],
    queryFn: () => fetchByEndpoint<CategoryBasedCollection>(placeholderCollectionUrl),
    enabled: !!placeholderCollectionUrl,
  });

  useNoticeError(error, { queryKey: placeholderSearchModalCollectionQueryKey, placeholderCollectionUrl });

  const limit = useFetchLimit(articleGrid.itemFetchLimit);

  if (isLoading) return <InfiniteCollectionSkeleton title="Placeholder Collection" limit={limit} />;

  if (!placeholderCollection) return null;

  const SearchContainer = styled("div")(({ theme }) => ({
    [`${InfiniteCollectionContainer}`]: {
      marginTop: theme.spacing(2),

      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(4),
      },
    },
  }));

  return (
    <SearchContainer>
      <DynamicContent {...placeholderCollection} />
    </SearchContainer>
  );
};

export default SearchModalPlaceholder;
