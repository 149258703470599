import { styled } from "@mui/material/styles";
import { colors, fonts } from "@constants/cssVariables";
import LinkListItem from "./LinkListItem";

const LinkListContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

const LinkListTitle = styled("p")(() => ({
  font: fonts.header3,
  color: colors.neutral90,
}));

const LinkListItemsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

interface LinkListProps {
  title: string;
  items: PageLink[];
}

const LinkList: React.FC<LinkListProps> = ({ title, items }) => {
  return (
    <LinkListContainer>
      <LinkListTitle>{title}</LinkListTitle>
      <LinkListItemsContainer>
        {items.map((item, index) => (
          <LinkListItem collectionTitle={title} key={item.id} position={index + 1} {...item} />
        ))}
      </LinkListItemsContainer>
    </LinkListContainer>
  );
};

export default LinkList;
