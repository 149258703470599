import { useMemo } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Pagination as MuiPagination, PaginationItem } from "@mui/material";
import {
  LeftBoldChevronIcon,
  LeftDoubleChevronIcon,
  RightBoldChevronIcon,
  RightDoubleChevronIcon,
} from "@common/Icons";
import { colors, fonts } from "@constants/cssVariables";

const Pagination: React.FC<{
  currentPage: number;
  count: number;
  onPageChange?: (page: number) => void;
}> = ({ currentPage, count, onPageChange }) => {
  const { asPath } = useRouter();
  const [currentPath] = asPath.split("?");

  const visiblePages = useMemo(() => {
    const calculatePages = (range: number) => {
      const pages = [];

      // Determine the start and end range for the visible pages
      let start = currentPage - Math.floor(range / 2);
      let end = currentPage + Math.floor(range / 2);

      // Adjust if start or end are out of bounds
      if (start < 1) {
        start = 1;
        end = Math.min(range, count);
      }

      if (end > count) {
        end = count;
        start = Math.max(1, end - (range - 1));
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      return pages;
    };
    return {
      md: calculatePages(5),
      sm: calculatePages(3),
    };
  }, [currentPage, count]);

  if (!count) return null;
  return (
    <MuiPagination
      sx={(theme) => ({
        marginBottom: 10,
        "& > .MuiPagination-ul": {
          justifyContent: "center",
        },
        "& .MuiPaginationItem-root": {
          font: fonts.bodySmall,
        },
        "& .MuiPaginationItem-root.Mui-selected": {
          backgroundColor: colors.primary,
          color: colors.neutral0,
        },
        "& .MuiPaginationItem-root.Mui-selected:hover": {
          backgroundColor: colors.neutral10,
          color: colors.neutral80,
        },
        "& .MuiPaginationItem-root:hover ": {
          backgroundColor: colors.neutral10,
          color: colors.neutral80,
        },
        "& .MuiPaginationItem-root.Mui-focusVisible": {
          backgroundColor: colors.neutral10,
        },
        "& .MuiPaginationItem-ellipsis": {
          display: "none",
        },
        "& svg": {
          width: 16,
          height: 16,
        },

        "& .MuiPaginationItem-page:not(.visible-md):not(.Mui-selected)": {
          display: "none",
        },
        [theme.breakpoints.down("md")]: {
          "& .MuiPaginationItem-page:not(.visible-sm):not(.Mui-selected)": {
            display: "none",
          },
        },
      })}
      siblingCount={2}
      page={currentPage}
      color="primary"
      count={count}
      showFirstButton
      showLastButton
      onChange={(_event, page) => onPageChange && page && onPageChange(page)}
      renderItem={(item) => (
        <PaginationItem
          {...(!onPageChange && {
            component: Link,
            href: `${item.page === 1 ? currentPath : `${currentPath}?page=${item.page}`}`,
          })}
          className={`${visiblePages.md.includes(item.page as number) ? "visible-md" : ""} ${
            visiblePages.sm.includes(item.page as number) ? "visible-sm" : ""
          }`}
          slots={{
            first: LeftDoubleChevronIcon,
            previous: LeftBoldChevronIcon,
            next: RightBoldChevronIcon,
            last: RightDoubleChevronIcon,
          }}
          {...item}
        />
      )}
    />
  );
};

export default Pagination;
