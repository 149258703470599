import React, { Fragment, useMemo } from "react";
import AdSlot from "@pageContent/AdSlot";
import SponsoredCard from "@pageContent/SponsoredCard";
import { AD_IDS } from "@constants/consts";
import { RichText as RichTextType } from "@constants/richText";
import { RootNode } from "@typings/richText";
import { documentToReactComponents, isValueNode } from "@utils/pageContent/richTextToJSX";
import { RichTextWrapper } from "./RichText.styled";

const mediaNodes = [
  RichTextType.IMAGE_EMBED,
  RichTextType.SOCIAL_EMBED,
  RichTextType.OL_LIST,
  RichTextType.UL_LIST,
  RichTextType.INLINE_PLAYER,
  RichTextType.HTML_EMBED,
];

const RichText: React.FC<{ richText: RootNode; isSponsored?: boolean }> = ({ richText, isSponsored }) => {
  const [adSlotPosition, sponsoredCardPosition] = useMemo(() => {
    if (isSponsored || !richText.content?.length) return [-1, -1];

    const adSlotIndex = 1;
    let paragraphCount = 0;
    for (let index = 0; index < richText.content.length; index++) {
      const currentNode = richText.content[index];
      let isHeaderParagraph = false;
      if (currentNode?.nodeType === RichTextType.PARAGRAPH) {
        paragraphCount++;
        isHeaderParagraph =
          currentNode.content.length === 1 &&
          isValueNode(currentNode.content[0]) &&
          currentNode.content[0].marks.some((mark: { type: string }) => mark.type === "bold");
      }

      if (paragraphCount >= 3) {
        const nextNode = richText.content[index + 1];

        if (!isHeaderParagraph && (!nextNode || !mediaNodes.includes(nextNode.nodeType))) {
          return [adSlotIndex, index];
        }
      }
    }
    return [adSlotIndex, -1];
  }, [richText, isSponsored]);

  return (
    <RichTextWrapper>
      {documentToReactComponents(richText).map((Item, index) => (
        <Fragment key={index}>
          {index === adSlotPosition && <AdSlot id={AD_IDS.contentBanner} />}
          {Item ? Item : null}
          {index === sponsoredCardPosition && <SponsoredCard />}
        </Fragment>
      ))}
    </RichTextWrapper>
  );
};

export default RichText;
