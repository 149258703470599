import { Suspense, useEffect, useState } from "react";
import { NextApiRequest } from "next";
import type { AppContext, AppInitialProps, AppProps } from "next/app";
import getConfig from "next/config";
import dynamic from "next/dynamic";
import Head from "next/head";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import Tealium from "@4tn/webx-analytics";
import { CacheProvider, EmotionCache } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import Footer from "@navigation/Footer";
import SearchModal from "@navigation/SearchModal";
import TopNavigation from "@navigation/TopNavigation";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ContentContainer from "@common/ContentContainer";
import CssVariables from "@common/CssVariables";
import ErrorBoundary from "@common/ErrorBoundary/ErrorBoundary";
import OopsPage from "@pageContent/OopsPage";
import { ROUTE_PATHS, tableNames } from "@constants/consts";
import "@styles/globals.css";
import Theme, { GlobalStyles } from "@styles/theme";
import acceptAllConsent from "@utils/common/acceptAllConsent";
import createEmotionCache from "@utils/common/createEmotionCache";
import detectAdBlock from "@utils/common/detectAdBlock";
import DynamoDBUtils from "@utils/common/dynamo";
import EventBus from "@utils/common/eventBus";
import featureTooling, { FeatureSlugs, useFeature } from "@utils/common/featureTooling";
import getHostConfig, { useHostConfig } from "@utils/common/getHostConfig";
import { setMainCategorySlugs } from "@utils/common/getPageCategory";
import isProdEnvironment from "@utils/common/isProdEnvironment";
import loadAppSmartBanner from "@utils/common/loadAppSmartBanner";
import { setCustomAttributes } from "@utils/common/newRelic";
import removeHash from "@utils/navigation/removeHash";

const OutletSwitch = dynamic(() => import("@common/OutletSwitch"), { ssr: false });

const { publicRuntimeConfig } = getConfig();

const clientSideEmotionCache = createEmotionCache();

const isClient = typeof window !== "undefined";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      gcTime: isClient ? 5 * 60 * 1000 : 0, // (2 minutes) no caching during SSR to prevent hydration errors
    },
  },
});

interface Tealium {
  profile: string;
  environment: string;
}

interface InitialProps {
  hamburgerMenu?: Navigation | null;
  topNavigation?: Navigation | null;
  footer?: Navigation | null;
  initialCategories?: Category[] | null;
  initialInApp?: boolean;
}
export interface HostAppProps extends AppProps, InitialProps {
  emotionCache?: EmotionCache;
}

export default function HostApp({
  Component,
  pageProps,
  hamburgerMenu,
  topNavigation,
  footer,
  initialCategories,
  initialInApp,
  emotionCache = clientSideEmotionCache,
}: HostAppProps) {
  const pathname = usePathname();
  const router = useRouter();
  const asPath = router.asPath;
  const [inApp] = useState(initialInApp);
  const { outletName, env, mfeName, tealium, appId, hostFile, designTokens } = useHostConfig();
  const { variables: smartBannerVariables } = useFeature<{ appToken: string }>(FeatureSlugs.APP_SMART_BANNER);

  useEffect(() => {
    if (smartBannerVariables?.appToken && !inApp) loadAppSmartBanner(smartBannerVariables.appToken);
  }, [smartBannerVariables, inApp]);

  useEffect(() => {
    if (window.location.href.includes("gdprBypass=true")) {
      acceptAllConsent();
    }

    if (!window.eventBus.initialized) {
      window.eventBus = new EventBus(window.eventBus);
    }

    if (tealium && typeof window.utag === "undefined") {
      Tealium.setup({
        account: "talpa",
        profile: tealium.profile,
        environment: tealium.environment,
        defaultLayer: {
          app_name: "webx platform",
          app_version: publicRuntimeConfig.version,
        },
      });

      detectAdBlock().then((detected) => {
        Tealium.setDataLayer({ adblock: detected });
      });
    }
  }, [tealium]);

  useEffect(() => {
    if (initialCategories) {
      setMainCategorySlugs(initialCategories.map(({ slug }) => slug));
      window.eventBus.dispatch("mainCategories", { categories: initialCategories });
    }
  }, [initialCategories]);

  useEffect(() => {
    window.eventBus.dispatch("hamburgerMenu", { isOpen: false });
  }, [asPath]);

  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash === "#consent") {
        window.OneTrust?.ToggleInfoDisplay();
        removeHash();
      }
    };

    handleHashChange();

    router.events.on("hashChangeComplete", handleHashChange);

    return () => {
      router.events.off("hashChangeComplete", handleHashChange);
    };
  }, [router.events]);

  useEffect(() => {
    featureTooling.reset();
  }, [pathname]);

  useEffect(() => {
    setCustomAttributes({ userAgent: window.navigator.userAgent });

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/service-worker.js", { scope: "/" }).catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Theme designTokens={designTokens}>
        <CssVariables />
        <CacheProvider value={emotionCache}>
          <Head>
            <title>{outletName}</title>
            <meta name="viewport" content="initial-scale=1, width=device-width" />
            {appId && <meta property="fb:appid" content={appId} />}
            <meta property="og:locale" content="nl_NL" />
            <meta property="og:site_name" content={outletName} />
            <meta property="og:logo" content={`/icons/${hostFile}/apple-icon-144x144.png`} />
            {asPath.startsWith(ROUTE_PATHS.ACCOUNT) && <meta name="robots" content="noindex" />}
          </Head>
          <CssBaseline />
          <GlobalStyles />
          {!inApp && (
            <Suspense fallback={<></>}>
              <TopNavigation topNavigation={topNavigation} hamburgerMenu={hamburgerMenu} />
            </Suspense>
          )}
          <ErrorBoundary onError={() => <OopsPage />}>
            <ContentContainer>
              <Component {...pageProps} />
            </ContentContainer>
          </ErrorBoundary>
          <Suspense fallback={<></>}>
            <ErrorBoundary onError={() => <></>}>
              <SearchModal />
            </ErrorBoundary>
          </Suspense>
          {!inApp && (
            <Suspense fallback={<></>}>
              <Footer footer={footer} />
            </Suspense>
          )}
          {(env !== "prod" || mfeName === "canary") && <OutletSwitch />}
        </CacheProvider>
      </Theme>
    </QueryClientProvider>
  );
}

HostApp.getInitialProps = async ({ Component, ctx }: AppContext): Promise<AppInitialProps & InitialProps> => {
  const isServer = !!ctx.req;
  const inApp = ctx.query?.inApp === "true";
  let pageProps = {};

  if (Component.getInitialProps) {
    const pageContext = isServer ? { ...ctx, dynamoDBUtils: DynamoDBUtils } : ctx;
    if (isServer) {
      const { cookies } = ctx.req as NextApiRequest;

      const outlet = isProdEnvironment() ? undefined : cookies?.outlet;

      // ensure that features are ready to be used in component's getInitialProps during SSR
      await featureTooling.fetchFeatures(getHostConfig(outlet).featureFile);
    }
    pageProps = await Component.getInitialProps(pageContext);
  }

  if (isServer && !inApp) {
    const [hamburgerMenu, topNavigation, footer, initialCategories] = await Promise.all([
      DynamoDBUtils.getItemBySlug<Navigation>(tableNames.navigation, "hamburger-menu").catch(() => null),
      DynamoDBUtils.getItemBySlug<Navigation>(tableNames.navigation, "tabbar-navigation").catch(() => null),
      DynamoDBUtils.getItemBySlug<Navigation>(tableNames.navigation, "footer").catch((error) => {
        console.log(error);
        return null;
      }),
      DynamoDBUtils.getMainCategories().catch((error) => {
        console.log(error);
        return null;
      }),
    ]);
    return {
      pageProps,
      hamburgerMenu,
      topNavigation,
      footer,
      initialCategories,
    };
  }

  return { pageProps, initialInApp: inApp };
};
