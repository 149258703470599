import { DEVICE, OS, PLATFORM } from "@constants/consts";
import featureTooling from "@utils/common/featureTooling";
import { getConsentData } from "@utils/common/getConsentData";
import getHostConfig from "@utils/common/getHostConfig";

export function isIos(): boolean {
  return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)/i) !== null;
}

export function isAndroid(): boolean {
  return navigator.userAgent.match(/(android)/i) !== null;
}

export function isMobile(): boolean {
  return navigator.userAgent.toLowerCase().match(/mobile/i) !== null;
}

export function isIosChrome(): boolean {
  return /CriOS/.test(navigator.userAgent) && isMobile();
}

export function isFacebook(): boolean {
  return navigator.userAgent.match(/FBAN|FBAV|FBIOS/i) !== null;
}

export function getSectionId(advertising: Advertising): string {
  const advertisementIds = [advertising.networkGroup, advertising.channelGroup, advertising.zoneGroup]
    .filter(Boolean)
    .join("_");
  if (isIos()) {
    return isFacebook()
      ? `${advertisementIds}_${OS.IOS}_${PLATFORM.FACEBOOK}`
      : `${advertisementIds}_${OS.IOS}_${PLATFORM.WEB}`;
  }
  if (isAndroid()) {
    return isFacebook()
      ? `${advertisementIds}_${OS.ANDROID}_${PLATFORM.FACEBOOK}`
      : `${advertisementIds}_${OS.ANDROID}_${PLATFORM.WEB}`;
  }
  if (isMobile()) {
    return `${advertisementIds}_${DEVICE.MOBILE}`;
  }
  return `${advertisementIds}_${DEVICE.DESKTOP}`;
}

export const getFreeWheelConfig = () => {
  const { advertising } = getHostConfig();
  const advertisingParameters: AdvertisingParameters = {
    client: "freewheel",
    cuetext: "Advertentie",
    vpaidcontrols: true,
    freewheel: {
      adManagerURL: advertising.adManagerURL,
      networkid: advertising.networkId,
      profileid: advertising.profileId,
      serverid: advertising.serverId,
      sectionid: getSectionId(advertising),
    },
    schedule: {
      adbreak: {
        offset: "pre",
        tag: "placeholder_preroll",
      },
    },
  };

  return advertisingParameters;
};

export const getCustomFreeWheelConfig = async (item: PlayListItem): Promise<CustomFreeWheelConfig> => {
  const { tcString, talpaConsent } = await getConsentData();

  const freeWheelConfig = {
    custom: {
      talpa_consent: talpaConsent,
      embed_domain: window.location.hostname,
      _fw_h_x_country: `${featureTooling.attributes.countryCode || ""}`,
      _fw_gdpr_consent: tcString,
      _fw_gdpr: "1",
      _fw_h_user_agent: navigator.userAgent,
      media_videotype: item.metadata.media_videotype,
      vdur: item.metadata.media_duration,
      cd_type: item.metadata.c_media_duration_type,
    },
  };

  return freeWheelConfig;
};
