export enum RichText {
  TEXT = "text",
  HYPERLINK = "hyperlink",
  PARAGRAPH = "paragraph",
  HEADING_2 = "heading-2",
  HEADING_3 = "heading-3",
  HEADING_4 = "heading-4",
  HEADING_5 = "heading-5",
  HEADING_6 = "heading-6",
  OL_LIST = "ordered-list",
  UL_LIST = "unordered-list",
  LIST_ITEM = "list-item",
  TABLE = "table",
  TABLE_ROW = "table-row",
  TABLE_CELL = "table-cell",
  TABLE_HEADER_CELL = "table-header-cell",
  QUOTE = "quote",
  TEXT_FRAME = "text-frame",
  IMAGE_EMBED = "image-embed",
  HTML_EMBED = "html-embed",
  LINK_LIST = "link-list",
  INLINE_PLAYER = "inline-player",
  VIDEO_COLLECTION = "video-collection",
  SOCIAL_EMBED = "social-embed",
  NEWSLETTER = "newsletter",
  RICH_TEXT = "rich-text",
  ACCORDION = "accordion",
  VIDEO_CATEGORY_COLLECTION = "video-category-collection",
}

export enum MarkType {
  BOLD = "bold",
  ITALIC = "italic",
  UNDERLINE = "underline",
}
