import React from "react";
import InfiniteCollection from "@pageContent/common/InfiniteCollection";
import { useHostConfig } from "@utils/common/getHostConfig";
import useFetchLimit from "@utils/pageContent/useFetchLimit";

const collectionName = "just-in-collection";

const defaultArticleGrid = {
  itemFetchLimit: {
    xs: 8,
    sm: 8,
    md: 9,
    lg: 8,
  },
};

const JustInCollection: React.FC<CategoryBasedCollection> = ({ title, endpoint, showCategory }) => {
  const { designTokens } = useHostConfig();

  const articlesGrid = designTokens?.articleGrid?.itemFetchLimit || defaultArticleGrid;

  const limit = useFetchLimit(articlesGrid);

  return (
    <InfiniteCollection
      title={title}
      endpoint={endpoint}
      limit={limit}
      collectionName={collectionName}
      showCategory={showCategory}
    />
  );
};

export default JustInCollection;
