export default function acceptAllConsent(): void {
  if (typeof window.OneTrust === "undefined") {
    setTimeout(acceptAllConsent, 50);
    return;
  }

  try {
    window.OneTrust.AllowAll();
  } catch {
    setTimeout(acceptAllConsent, 50);
  }
}
