import { useEffect, useRef } from "react";
import Tealium, { EventLog } from "@4tn/webx-analytics";
import { CONTENT_LANGUAGE } from "@constants/consts";
import { Episode } from "@utils/video/dataModels";
import getPageCategory, { getRouteSlug } from "./getPageCategory";

interface PageViewProps {
  isLoading: boolean;
  data: unknown;
  error?: Error | null;
}

const getContentMetadata = (entry: unknown): Record<string, string | null> => {
  if (!entry) return {};

  const { title, createdAt, updatedAt } = entry as Article | Page | CategoryEntry | Video | Episode;
  const fields: Record<string, string | null> = {
    content_name: title || null,
    content_language: CONTENT_LANGUAGE,
    content_datecreated: createdAt || null,
    content_datepublished: createdAt || null,
    content_datemodified: updatedAt || null,
  };

  if ((entry as Article).category) {
    fields.content_category = (entry as Article).category.title;
  }

  if ((entry as Episode).subtitle) {
    const episode = entry as Episode;
    fields.content_name = `${episode.title}: ${episode.subtitle}`;
  }

  return fields;
};

const usePageView = ({ isLoading, data, error }: PageViewProps) => {
  const hasSentAdvertorialEvent = useRef(false);

  useEffect(() => {
    if (isLoading || (data === undefined && !error)) return;
    if (EventLog.getLastEvent("view")?.page_url !== document.location.href) {
      const pageCategory = getPageCategory(
        document.location.pathname,
        (data as Article)?.hasVideo ?? false,
        !!((data as Article)?.sponsor ?? false)
      );
      const pageId = getRouteSlug(document.location.pathname);

      Tealium.setDataLayer({
        page_category: pageCategory,
        page_id: pageId,
      });

      const contentMetadata = getContentMetadata(data);

      Tealium.view({
        event_label: document.title,
        page_id: pageId,
        page_category: pageCategory,
        content_id: pageId,
        content_type: pageCategory,
        ...contentMetadata,
      });

      if ((data as Article)?.sponsor && !hasSentAdvertorialEvent.current) {
        const timer = setTimeout(() => {
          Tealium.link({
            event_action: "advertorials",
            event_category: "actual_time_on_page",
            event_label: 15,
            content_id: pageId,
            content_type: pageCategory,
            ...contentMetadata,
          });
          hasSentAdvertorialEvent.current = true;
        }, 15000);

        return () => clearTimeout(timer);
      }
    }
  }, [isLoading, data, error]);
};

export default usePageView;
