import React from "react";
import { useQuery } from "@tanstack/react-query";
import OopsPage from "@pageContent/OopsPage";
import Pagination from "@pageContent/Pagination";
import {
  InfiniteCollectionContainer,
  ItemsContainer,
  Title,
  TitleWrapper,
} from "@pageContent/common/InfiniteCollection/InfiniteCollection";
import InfiniteCollectionItem from "@pageContent/common/InfiniteCollection/InfiniteCollectionItem";
import { CollectionSkeleton } from "@pageContent/common/Skeletons/InfiniteCollectionSkeleton";
import { fetchLinks } from "@utils/pageContent/fetchData";
import useFetchLimit from "@utils/pageContent/useFetchLimit";
import * as Styled from "./PaginatedOverview.styled";

export const noResultText = "Helaas kunnen we geen items vinden.";

export const initialFetchedItemsNumber = {
  xs: 16,
  sm: 16,
  md: 18,
  lg: 16,
};

const PaginatedOverview: React.FC<{
  collectionTitle: string;
  page: number;
  isVideoOverview?: boolean;
  category?: {
    articleEndpoint: string;
    videoEndpoint?: string;
  };
  onPageChange?: (page: number) => void;
  activeId?: string;
  initialData?: ReturnFetchData<PageLink>;
  showCategory?: boolean;
}> = ({ category, collectionTitle, page, isVideoOverview, initialData, activeId, showCategory, onPageChange }) => {
  const limit = useFetchLimit(initialFetchedItemsNumber);

  const { articleEndpoint, videoEndpoint } = category || {};
  const skip = (page - 1) * limit;

  const { data, isLoading, error } = useQuery({
    queryKey: ["category-overview", isVideoOverview, videoEndpoint, articleEndpoint, skip, limit],
    queryFn: () => {
      const endpoint = isVideoOverview ? videoEndpoint : articleEndpoint;
      if (!endpoint) return;

      return fetchLinks({ endpoint, limit, page });
    },
    enabled: !!category,
    initialData,
  });

  const { items, totalResults } = data || {};
  const count = totalResults ? Math.ceil(totalResults / limit) : 0;

  if (isLoading || !category)
    return (
      <InfiniteCollectionContainer>
        <TitleWrapper>
          <Title>{collectionTitle}</Title>
        </TitleWrapper>

        <ItemsContainer>
          <CollectionSkeleton title={collectionTitle} limit={limit} />
        </ItemsContainer>
      </InfiniteCollectionContainer>
    );

  if (!items) return <OopsPage error={error} />;

  return (
    <>
      <InfiniteCollectionContainer>
        <TitleWrapper>
          <Title>{collectionTitle}</Title>
        </TitleWrapper>
      </InfiniteCollectionContainer>
      {items.length ? (
        <Styled.CollectionItems>
          {items.map((item, index) => (
            <InfiniteCollectionItem
              key={item.id}
              position={index + 1}
              showCategory={showCategory}
              sectionTitle={collectionTitle}
              isActive={!!item.videoGuid && item.videoGuid === activeId}
              link={item}
            />
          ))}
        </Styled.CollectionItems>
      ) : (
        <Styled.NoItemsText>{noResultText}</Styled.NoItemsText>
      )}

      {!!count && <Pagination count={count} currentPage={page} onPageChange={onPageChange} />}
    </>
  );
};
export default PaginatedOverview;
